import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/main/navbar';
import Hero from './components/sections/hero/hero';
import About from './components/sections/about/about';
import Store from './components/sections/store/store';
import Customers from './components/sections/customers/customers';
import Footer from './components/main/footer';
import Login from './components/webshopv1/Login';
import Dashboard from './components/webshopv1/Dashboard';
import Order from './components/webshopv1/Order';
import Orders from './components/webshopv1/dashboard/Orders';
import Products from './components/webshopv1/dashboard/Products';
import PrivateRoute from './components/webshopv1/PrivateRoute';
import { CartProvider } from './context/CartContext';
import './App.css'; // Make sure to import your CSS file

function AppContent({ homeRef, storeRef, aboutRef, customersRef, scrollToSection }) {
    const location = useLocation();
    const isWebshopRoute = location.pathname.startsWith('/dashboard') ||
        location.pathname.startsWith('/orders') ||
        location.pathname.startsWith('/products');
    const isAuthRoute = location.pathname === '/login' || location.pathname === '/order';

    return (
        <div className="AppContent">
            {/* Render Navbar conditionally */}
            {!isAuthRoute && !isWebshopRoute && (
                <Navbar
                    scrollToSection={scrollToSection}
                    homeRef={homeRef}
                    storeRef={storeRef}
                    aboutRef={aboutRef}
                />
            )}

            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <div ref={homeRef}><Hero /></div>
                            <div ref={storeRef}><Store /></div>
                            <div ref={aboutRef}><About /></div>
                        </>
                    }
                />
                <Route path="/store" element={<Store />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<Login />} />
                <Route path="/order" element={<Order />} />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
                <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
            </Routes>

            {/* Render Footer conditionally */}
            {!isWebshopRoute && !isAuthRoute && <Footer />}
        </div>
    );
}

function App() {
    const homeRef = useRef(null);
    const storeRef = useRef(null);
    const aboutRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/*"
                    element={<AppContent
                        homeRef={homeRef}
                        storeRef={storeRef}
                        aboutRef={aboutRef}
                        scrollToSection={scrollToSection}
                    />}
                />
            </Routes>
        </div>
    );
}

export default function AppWrapper() {
    return (
        <Router>
            <CartProvider>
                <div className="App">
                    <App />
                </div>
            </CartProvider>
        </Router>
    );
}