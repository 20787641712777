// src/components/sections/hero/Hero.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './hero.scss';

function Hero() {
    const navigate = useNavigate();
    const [text, setText] = useState({
        title: 'Welcome to Our Site',
        subtitle: 'Your journey starts here',
    });

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'EN';
        updateText(savedLanguage);

        const handleLanguageChange = () => {
            const currentLanguage = localStorage.getItem('language') || 'EN';
            updateText(currentLanguage);
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, []);

    const updateText = (language) => {
        if (language === 'SRB') {
            setText({
                title: 'Dobrodošli na Janami Wood',
                subtitle: 'Započnite svoju priču s pravim drvetom',
            });
        } else {
            setText({
                title: 'Welcome to Janami Wood',
                subtitle: 'Begin your story with real wood',
            });
        }
    };

    const handleImageClick = () => {
        navigate('/login');
    };

    return (
        <div className="hero">
            <img
                src={`/assets/image1.jpg`}
                alt="Hero Background"
                className="hero__image"
                onClick={handleImageClick}
                style={{ cursor: 'pointer' }}
            />
            <div className="hero__text">
                <h1>{text.title}</h1>
                <p>{text.subtitle}</p>
            </div>
        </div>
    );
}

export default Hero;