// src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

// Ovo je primer funkcije za proveru autentičnosti
const isAuthenticated = () => {
    // Proveri da li auth token postoji u localStorage
    return localStorage.getItem('authToken') !== null;
};

const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;