import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./dashbar.scss"

function DashBar() {
    const navigate = useNavigate();

    const handleSignOut = () => {
        // Logic for signing out can be added here
        navigate('/login'); // Redirect to login page
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="dashboard-container">
            <nav className="dashbar">
                <div className="dashbar-links">
                    <button
                        className="dashbar-button overview-button"
                        onClick={() => handleNavigation('/dashboard')}
                        aria-label="Overview"
                    >
                        Overview
                    </button>
                    <button
                        className="dashbar-button products-button"
                        onClick={() => handleNavigation('/products')}
                        aria-label="Products"
                    >
                        Products
                    </button>
                    <button
                        className="dashbar-button orders-button"
                        onClick={() => handleNavigation('/orders')}
                        aria-label="Orders"
                    >
                        Orders
                    </button>
                    <button
                        className="dashbar-button signout-button"
                        onClick={handleSignOut}
                        aria-label="Sign Out"
                    >
                        Sign Out
                    </button>
                </div>
            </nav>
        </div>
    );
}

export default DashBar;