import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './style/order.scss';
import { useCart } from '../../context/CartContext';

const initialOrderData = {
    name: '',
    lastname: '',
    country: '',
    city: '',
    address: '',
    zipCode: '',
    email: '',
    phoneNumber: '',
    products: [{ productId: '', quantity: '', engraving: false }],
    paymentStatus: 'pending', // Payment status field
    status: 'pending', // Order status field
    payLater: false,
};

const translations = {
    EN: {
        title: 'Create Order',
        name: 'Name',
        lastname: 'Last Name',
        country: 'Country',
        city: 'City',
        address: 'Address',
        zipCode: 'ZIP Code',
        email: 'Email',
        phoneNumber: 'Phone Number',
        product: 'Product',
        chooseProduct: 'Choose Product',
        quantity: 'Quantity',
        engraving: 'Engraving',
        addProduct: 'Add Product',
        totalPrice: 'Total Price',
        submitOrder: 'Submit Order',
        cancelOrder: 'Cancel Order',
        payWithPaypal: 'Pay with PayPal',
        payLater: 'Pay Later',
        noEngraving: 'No engraving available',
    },
    SRB: {
        title: 'Kreiraj Narudžbinu',
        name: 'Ime',
        lastname: 'Prezime',
        country: 'Država',
        city: 'Grad',
        address: 'Adresa',
        zipCode: 'Poštanski kod',
        email: 'Email',
        phoneNumber: 'Broj telefona',
        product: 'Proizvod',
        chooseProduct: 'Izaberite proizvod',
        quantity: 'Količina',
        engraving: 'Graviranje',
        addProduct: 'Dodaj Proizvod',
        totalPrice: 'Ukupna Cena',
        submitOrder: 'Pošalji Narudžbinu',
        cancelOrder: 'Otkaži Narudžbinu',
        payWithPaypal: 'Plati sa PayPal-om',
        payLater: 'Plati kasnije',
        noEngraving: 'Nema graviranja',
    },
};

function Order() {
    const navigate = useNavigate();
    const { cart, clearCart } = useCart();
    const [orderData, setOrderData] = useState({
        ...initialOrderData,
        products: cart,
    });
    const [productsList, setProductsList] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [translatedTexts, setTranslatedTexts] = useState(translations['EN']);
    const [paypalClicked, setPaypalClicked] = useState(false);

    useEffect(() => {
        fetchProducts();

        const handleLanguageChange = () => {
            const storedLanguage = localStorage.getItem('language') || 'EN';
            setLanguage(storedLanguage);
            setTranslatedTexts(translations[storedLanguage]);
            if (storedLanguage === 'SRB') {
                setOrderData((prevOrderData) => ({
                    ...prevOrderData,
                    payLater: true,
                }));
            }
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        handleLanguageChange();

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://api.janamiwood.com/products');
            setProductsList(response.data);
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData({
            ...orderData,
            [name]: value,
        });
    };

    const handleProductChange = (index, e) => {
        const { name, value } = e.target;
        const newProducts = orderData.products.map((product, i) => {
            if (i === index) {
                return { ...product, [name]: name === 'engraving' ? e.target.checked : value };
            }
            return product;
        });
        setOrderData({
            ...orderData,
            products: newProducts,
        });
    };

    const addProduct = () => {
        setOrderData({
            ...orderData,
            products: [...orderData.products, { productId: '', quantity: '', engraving: false }],
        });
    };

    const removeProduct = (index) => {
        const newProducts = orderData.products.filter((_, i) => i !== index);
        setOrderData({
            ...orderData,
            products: newProducts,
        });
    };

    const validateOrderData = () => {
        const { name, lastname, country, city, address, zipCode, email, phoneNumber, products } = orderData;

        if (!name || !lastname || !country || !city || !address || !zipCode || !email || !phoneNumber || !products.length) {
            return false;
        }

        for (let product of products) {
            if (!product.productId || !product.quantity) {
                return false;
            }
        }

        if (!paypalClicked && !orderData.payLater) {
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateOrderData()) {
            alert('Please provide all required fields and select a payment method.');
            return;
        }

        // Set the order status based on the language and payment method selected
        const updatedOrderData = { ...orderData };
        if (language === 'EN') {
            updatedOrderData.paymentStatus = paypalClicked ? 'completed' : 'pending';
            updatedOrderData.status = paypalClicked ? 'paypal' : 'pay later';
        } else if (language === 'SRB') {
            updatedOrderData.paymentStatus = 'pending';
            updatedOrderData.status = 'pouzeće';
        }

        axios.post('https://api.janamiwood.com/orders', updatedOrderData)
             .then((response) => {
                 alert('Order created successfully.');
                 setOrderData(initialOrderData);
                 clearCart();
                 navigate('/');
             })
             .catch((error) => {
                 alert('Failed to create order.');
                 console.error(error);
             });
    };

    const handleCancel = () => {
        setOrderData(initialOrderData);
        clearCart();
        navigate('/');
    };

    const calculateTotalPrice = () => {
        return orderData.products.reduce((total, product) => {
            const productDetails = productsList.find((p) => p.id === parseInt(product.productId));
            if (productDetails) {
                const price = language === 'EN' ? productDetails.priceUSD : productDetails.priceRSD;
                return total + price * product.quantity;
            }
            return total;
        }, 0);
    };

    const handlePaypalPayment = () => {
        const totalUSD = orderData.products.reduce((total, product) => {
            const productDetails = productsList.find((p) => p.id === parseInt(product.productId));
            if (productDetails) {
                return total + productDetails.priceUSD * product.quantity;
            }
            return total;
        }, 0);
        setPaypalClicked(true);
        window.open(`https://paypal.me/RaleBajat/${totalUSD.toFixed(2)}`, '_blank');
    };

    return (
        <div className="order-page">
            <div className="order-container">
                <h1 className="order-title">{translatedTexts.title}</h1>
                <form onSubmit={handleSubmit} className="order-form">
                    <div className="order-form-group">
                        <label htmlFor="name">{translatedTexts.name}:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={orderData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="lastname">{translatedTexts.lastname}:</label>
                        <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={orderData.lastname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="country">{translatedTexts.country}:</label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            value={orderData.country}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="city">{translatedTexts.city}:</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            value={orderData.city}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="address">{translatedTexts.address}:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={orderData.address}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="zipCode">{translatedTexts.zipCode}:</label>
                        <input
                            type="text"
                            id="zipCode"
                            name="zipCode"
                            value={orderData.zipCode}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="email">{translatedTexts.email}:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={orderData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="order-form-group">
                        <label htmlFor="phoneNumber">{translatedTexts.phoneNumber}:</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={orderData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {orderData.products.map((product, index) => {
                        const foundProduct = productsList.find((p) => p.id === parseInt(product.productId));
                        return (
                            <div key={index} className="order-product-group">
                                <label>{translatedTexts.product}:</label>
                                <select
                                    name="productId"
                                    value={product.productId}
                                    onChange={(e) => handleProductChange(index, e)}
                                    required>
                                    <option value="">{translatedTexts.chooseProduct}</option>
                                    {productsList.map((prod) => (
                                        <option key={prod.id} value={prod.id}>
                                            {prod.name} - {language === 'EN' ? `$${prod.priceUSD.toFixed(2)}` : `${prod.priceRSD} RSD`}
                                        </option>
                                    ))}
                                </select>
                                {foundProduct && (
                                    <div className="order-product-price">
                                        <p>
                                            Price: {language === 'EN' ? `$${foundProduct.priceUSD.toFixed(2)}` : `${foundProduct.priceRSD} RSD`}
                                        </p>
                                    </div>
                                )}
                                <label>{translatedTexts.quantity}:</label>
                                <input
                                    type="number"
                                    name="quantity"
                                    value={product.quantity}
                                    onChange={(e) => handleProductChange(index, e)}
                                    required
                                    min="1"
                                />
                                {foundProduct ? (
                                    foundProduct.engraving ? (
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="engraving"
                                                checked={product.engraving}
                                                onChange={(e) => handleProductChange(index, e)}
                                            />
                                            {translatedTexts.engraving}
                                        </label>
                                    ) : (
                                        <p>{translatedTexts.noEngraving}</p>
                                    )
                                ) : null}
                                <button type="button" className="button-remove" onClick={() => removeProduct(index)}>
                                    Remove
                                </button>
                            </div>
                        );
                    })}

                    <button type="button" onClick={addProduct} className="order-add-product">
                        {translatedTexts.addProduct}
                    </button>

                    <div className="order-total-price">
                        <h2>
                            {translatedTexts.totalPrice}:{' '}
                            {language === 'EN' ? `$${calculateTotalPrice().toFixed(2)}` : `${calculateTotalPrice().toFixed(2)} RSD`}
                        </h2>
                    </div>

                    {language === 'EN' && (
                        <div className="order-payment-options">
                            <button type="button" className="order-paypal" onClick={handlePaypalPayment}>
                                {translatedTexts.payWithPaypal}
                            </button>
                            <span className="payment-separator">OR</span>
                            <label className="order-pay-later">
                                <input
                                    type="checkbox"
                                    name="payLater"
                                    checked={orderData.payLater}
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            payLater: e.target.checked,
                                        })
                                    }
                                />
                                {translatedTexts.payLater}
                            </label>
                        </div>
                    )}

                    <button type="submit" className="order-submit">
                        {translatedTexts.submitOrder}
                    </button>
                    <button type="button" className="order-cancel" onClick={handleCancel}>
                        {translatedTexts.cancelOrder}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Order;