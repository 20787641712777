import React from 'react';
import './footer.scss';
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__contact">
                <FaPhone className="footer__icon" />
                <span className="footer__text">+381 62 84 88 638</span>

                <FaEnvelope className="footer__icon" />
                <span className="footer__text">info@janamiwood.com</span>
            </div>

            <div className="footer__social">
                <a href="https://www.facebook.com/janami.wood" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="footer__icon"/>
                    <span className="footer__text">Facebook</span>
                </a>
                <a href="https://www.instagram.com/janami_wood" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="footer__icon"/>
                    <span className="footer__text">Instagram</span>
                </a>
            </div>

            <div className="footer__brand">Janami Wood</div>

            <hr className="footer__divider"/>

            <div className="footer__copyright">© 2024 Dreamstrl. All rights reserved.</div>
        </footer>
    );
}

export default Footer;