import React from 'react';
import { useNavigate } from 'react-router-dom';
import './dashfooter.scss';


function Dashdashfooter() {
    const navigate = useNavigate();

    const handleSignOut = () => {
        // Logic for signing out
        navigate('/login'); // Redirect to login page
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
            <dashfooter className="dashfooter">
                <img src="./logoDSRL.png" alt="Logo" className="dashfooter-logo"/>
                <div className="dashfooter-text">
                    <h4>DASHBOARD PANEL</h4>
                    <p>
                        &copy; {new Date().getFullYear()} <a href="https://dreamstrl.com" target="_blank" rel="noopener noreferrer">dreamstrl.com</a> - All rights reserved.
                    </p>
                </div>
            </dashfooter>
    );
}

export default Dashdashfooter;