import React, { createContext, useContext, useState } from 'react';

// Stvaranje konteksta za korpu
const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const addToCart = (product) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find(p => p.productId === product.productId);

            if (existingProduct) {
                return prevCart.map(p =>
                    p.productId === product.productId
                        ? { ...p, quantity: p.quantity + 1, engraving: product.engraving }
                        : p
                );
            }
            return [...prevCart, { ...product, quantity: 1 }];
        });
    };

    const removeFromCart = (productId) => {
        setCart((prevCart) => prevCart.filter(product => product.productId !== productId));
    };

    const updateProductQuantity = (productId, quantity) => {
        setCart((prevCart) =>
            prevCart.map(product =>
                product.productId === productId ? { ...product, quantity } : product
            )
        );
    };

    const clearCart = () => setCart([]);

    const getCartCount = () => cart.reduce((count, product) => count + product.quantity, 0);

    const getCartTotal = () => cart.reduce((total, product) => total + product.price * product.quantity, 0);

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateProductQuantity, clearCart, getCartCount, getCartTotal }}>
            {children}
        </CartContext.Provider>
    );
};