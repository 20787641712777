import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.scss';
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../../context/CartContext'; // Uverite se da je putanja tačna

function Navbar({ scrollToSection, homeRef, storeRef, aboutRef, customersRef }) {
    const navigate = useNavigate();
    const [language, setLanguage] = useState('EN');
    const { getCartCount } = useCart();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'EN';
        setLanguage(storedLanguage);
    }, []);

    const handleCartClick = () => {
        navigate('/order');
    };

    const handleLogoClick = () => {
        navigate('/login');
    };

    const changeLanguage = (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        const event = new Event('languageChanged');
        window.dispatchEvent(event);
    };

    const translations = {
        EN: {
            home: 'Home',
            store: 'Store',
            about: 'About',
            customers: 'Customers'
        },
        SRB: {
            home: 'Početna',
            store: 'Prodavnica',
            about: 'O nama',
            customers: 'Kupci'
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar__logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                <img src={`/logo.png`} alt="Logo" className="logo" />
            </div>
            <ul className="navbar__links">
                <li onClick={() => scrollToSection(homeRef)}>{translations[language].home}</li>
                <li onClick={() => scrollToSection(storeRef)}>{translations[language].store}</li>
                <li onClick={() => scrollToSection(aboutRef)}>{translations[language].about}</li>
                <li onClick={() => scrollToSection(customersRef)}>{translations[language].customers}</li>
            </ul>
            <div className="navbar__icons">
                <div onClick={handleCartClick} style={{ cursor: 'pointer', position: 'relative' }}>
                    <FaShoppingCart className="icon" />
                    {getCartCount() > 0 && (
                        <span className="navbar__badge">{getCartCount()}</span>
                    )}
                </div>
                <select className="language-picker" value={language} onChange={changeLanguage}>
                    <option value="EN">EN</option>
                    <option value="SRB">SRB</option>
                </select>
            </div>
        </nav>
    );
}

export default Navbar;