import React from 'react';
import DashBar from './main/dashbar';
import DashFooter from './main/dashfooter';
import './style/dashboard.scss';

function Dashboard() {
    return (
        <>
            <DashBar />
            <div className="dashboard-section">
                <div className="main-wrapper">
                    <div className="main-content">
                        <h1>Overview</h1>
                        <p>Welcome to the dashboard. Here you can find an overview of your application state, recent activities, and more.</p>
                    </div>
                </div>
            </div>
            <DashFooter />
        </>
    );
}

export default Dashboard;