import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './storecards.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCart } from '../../../context/CartContext'; // Import useCart from context

const StoreCards = ({ showAll }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { addToCart } = useCart(); // Destructure addToCart from useCart context
  const [language, setLanguage] = useState('EN'); // Default language

  useEffect(() => {
    fetchProducts();
    const savedLanguage = localStorage.getItem('language') || 'EN';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'EN';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://api.janamiwood.com/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  const handleCheckboxChange = (productId, engraving) => {
    setSelectedProducts(prevSelectedProducts => {
      const existingProduct = prevSelectedProducts.find(product => product.productId === productId);
      if (existingProduct) {
        return prevSelectedProducts.map(product =>
            product.productId === productId ? { ...product, engraving } : product
        );
      } else {
        return [...prevSelectedProducts, { productId, engraving }];
      }
    });
  };

  const handleAddToCart = (productId) => {
    const product = products.find(p => p.id === productId);
    const selectedProduct = selectedProducts.find(p => p.productId === productId);
    const engraving = selectedProduct ? selectedProduct.engraving : false;

    if (product) {
      addToCart({
        productId: product.id,
        name: product.name,
        // Check for price based on language
        price: language === 'EN' ? product.priceUSD : product.priceRSD,
        engraving: engraving ? 1 : undefined, // Only add engraving if it is true
        quantity: 1 // Assume adding one item initially
      });
    }
  };

  const visibleProducts = showAll ? products : products.slice(0, 4);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
      <div className="storecards">
        {visibleProducts.map((product) => (
            <div key={product.id} className="storecard">
              <Slider {...sliderSettings} className="storecard-slider">
                {product.image1 && <div className="storecard-slider-image"><img src={product.image1} alt={`${product.name} 1`} className="storecard-image" /></div>}
                {product.image2 && <div className="storecard-slider-image"><img src={product.image2} alt={`${product.name} 2`} className="storecard-image" /></div>}
                {product.image3 && <div className="storecard-slider-image"><img src={product.image3} alt={`${product.name} 3`} className="storecard-image" /></div>}
              </Slider>
              <h3 className="storecard-name">{product.name}</h3>
              <p className="storecard-description">
                {language === 'EN' ? (
                    <>
                      <strong>Description :</strong> {product.descriptionEN}
                    </>
                ) : (
                    <>
                      <strong>Opis :</strong> {product.descriptionSR}
                    </>
                )}
              </p>
              {product.engraving ? (
                  <div className="storecard-checkbox">
                    <input
                        type="checkbox"
                        id={`engraving-${product.id}`}
                        onChange={(e) => handleCheckboxChange(product.id, e.target.checked)}
                    />
                    <label htmlFor={`engraving-${product.id}`}>Engraving (Additional cost)</label>
                  </div>
              ) : (
                  <p className="storecard-engraving-not-available">No engraving available</p>
              )}
              <div className="storecard-footer">
            <span className="storecard-price">
              {language === 'EN' ? `${product.priceUSD} USD` : `${product.priceRSD} RSD`}
            </span>
                <button className="storecard-button" onClick={() => handleAddToCart(product.id)}>Add to Cart</button>
              </div>
            </div>
        ))}
      </div>
  );
};

export default StoreCards;