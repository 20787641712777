import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashBar from '../main/dashbar';
import DashFooter from '../main/dashfooter';
import './style/orders.scss';

function Orders() {
    const navigate = useNavigate();
    const [ordersData, setOrdersData] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://api.janamiwood.com/orders');
                setOrdersData(response.data);

                // Add fade-in animation after content loads
                const orderContent = document.querySelector('.orders-content');
                if (orderContent) {
                    orderContent.classList.add('fade-in');
                }
            } catch (error) {
                console.error('Failed to fetch orders:', error);
            }
        };

        fetchOrders();
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const updatePaymentStatus = async (orderId, newPaymentStatus) => {
        try {
            await axios.put(`https://api.janamiwood.com/orders/${orderId}/paymentStatus`, { paymentStatus: newPaymentStatus });
            setOrdersData(ordersData.map(order => order.id === orderId ? { ...order, paymentStatus: newPaymentStatus } : order));
        } catch (error) {
            console.error('Failed to update payment status:', error);
        }
    };

    const updateOrderStatus = async (orderId, newStatus) => {
        try {
            await axios.put(`https://api.janamiwood.com/orders/${orderId}/status`, { status: newStatus });
            setOrdersData(ordersData.map(order => order.id === orderId ? { ...order, status: newStatus } : order));
        } catch (error) {
            console.error('Failed to update order status:', error);
        }
    };

    const deleteOrder = async (orderId) => {
        try {
            await axios.delete(`https://api.janamiwood.com/orders/${orderId}`);
            setOrdersData(ordersData.filter(order => order.id !== orderId));
        } catch (error) {
            console.error('Failed to delete order:', error);
        }
    };

    return (
        <>
            <DashBar />
            <div className="orders-container">
                <div className="orders-content">
                    <div className="orders-box">
                        <h1>Orders</h1>
                        <div className="orders-list">
                            {ordersData.map((order, index) => {
                                const totalRSD = order.products.reduce((total, product) => total + (product.priceRSD * product.quantity), 0);
                                const totalUSD = order.products.reduce((total, product) => total + (product.priceUSD * product.quantity), 0);

                                return (
                                    <div className="orders-card" key={index}>
                                        <h2>{order.name} {order.lastname}</h2>
                                        <p><strong>Country:</strong> {order.country}</p>
                                        <p><strong>City:</strong> {order.city}</p>
                                        <p><strong>Address:</strong> {order.address}</p>
                                        <p><strong>ZIP Code:</strong> {order.zipCode}</p>
                                        <p><strong>Email:</strong> {order.email}</p>
                                        <p><strong>Phone Number:</strong> {order.phoneNumber}</p>
                                        <p><strong>Payment Status:</strong> {order.paymentStatus}</p>
                                        <p><strong>Status:</strong> {order.status}</p>

                                        <div className="orders-products">
                                            <h3>Products:</h3>
                                            {order.products.map((product, i) => (
                                                <div className="orders-product" key={i}>
                                                    <p><strong>Name:</strong> {product.name}</p>
                                                    <p><strong>Price (RSD):</strong> {product.priceRSD}</p>
                                                    <p><strong>Price (USD):</strong> {product.priceUSD}</p>
                                                    <p><strong>Quantity:</strong> {product.quantity}</p>
                                                    <p><strong>Engraving:</strong> {product.engraving ? 'Yes' : 'No'}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <p><strong>Total (RSD):</strong> {totalRSD.toFixed(2)}</p>
                                        <p><strong>Total (USD):</strong> {totalUSD.toFixed(2)}</p>

                                        <button className="order-action-button" onClick={() => updatePaymentStatus(order.id, 'Paid')}>
                                            Mark as Paid
                                        </button>
                                        <button className="order-action-button" onClick={() => updateOrderStatus(order.id, 'Shipped')}>
                                            Mark as Shipped
                                        </button>
                                        <button className="order-action-button" onClick={() => updateOrderStatus(order.id, 'Delivered')}>
                                            Mark as Delivered
                                        </button>
                                        <button className="order-delete-button" onClick={() => deleteOrder(order.id)}>
                                            Delete Order
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <DashFooter />
        </>
    );
}

export default Orders;