import React, { useState, useEffect } from 'react';
import './store.scss';
import StoreCards from './storecards';

function Store() {
    const [showAll, setShowAll] = useState(false);
    const [text, setText] = useState({
        header: 'Store',
        buttonShowMore: 'Show More',
        buttonShowLess: 'Show Less',
    });

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'EN';
        updateText(savedLanguage);

        const handleLanguageChange = () => {
            const currentLanguage = localStorage.getItem('language') || 'EN';
            updateText(currentLanguage);
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, []);

    const updateText = (language) => {
        if (language === 'SRB') {
            setText({
                header: 'Prodavnica',
                buttonShowMore: 'Prikaži Više',
                buttonShowLess: 'Prikaži Manje',
            });
        } else {
            setText({
                header: 'Store',
                buttonShowMore: 'Show More',
                buttonShowLess: 'Show Less',
            });
        }
    };

    const handleToggleShow = () => {
        setShowAll(prevState => !prevState);
    };

    return (
        <div className="store">
            <h2 className="store-header">{text.header}</h2>
            <StoreCards showAll={showAll} />

            <button className="more-button" onClick={handleToggleShow}>
                {showAll ? text.buttonShowLess : text.buttonShowMore}
            </button>
        </div>
    );
}

export default Store;