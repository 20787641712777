// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './style/login.scss';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');  // Clear the previous error message

        try {
            const response = await axios.post('https://api.janamiwood.com/login', { username, password });
            if (response.data.message === 'Login successful.') {
                localStorage.setItem('authToken', response.data.token);  // Save token in localStorage
                navigate('/dashboard');
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const handleGoBack = () => {
        navigate('/'); // Navigate to the root page
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <img src="./logo512.png" alt="Logo" className="login-logo"/>
                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div className="button-group">
                        <button type="submit" className="login-button">Login</button>
                        <button type="button" className="back-button" onClick={handleGoBack}>Go Back</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;