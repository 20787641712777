import React, { useEffect, useState } from 'react';
import './about.scss';

function About() {
    const [text, setText] = useState({
        title: 'About Us',
        content: `
      Janami Wood is a workshop dedicated to creating high-quality wooden combs. Each of our products is carefully crafted from the finest wood, choosing materials that are natural, durable, and environmentally friendly. Our mission is to provide our customers with combs that are not only functional but also aesthetically appealing.
      <br/><br/>
      In addition to making top-notch products, we are also committed to preserving nature. We use sustainable production methods and choose materials that minimize waste and have the smallest ecological footprint. Choose Janami Wood and enjoy products that combine quality, elegance, and environmental responsibility.
    `
    });

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'EN';
        updateText(savedLanguage);

        const handleLanguageChange = () => {
            const currentLanguage = localStorage.getItem('language') || 'EN';
            updateText(currentLanguage);
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, []);

    const updateText = (language) => {
        if (language === 'SRB') {
            setText({
                title: 'O nama',
                content: `
          Janami Wood je proizvodna radionica posvećena izradi visokokvalitetnih drvenih češljeva. Svaki naš proizvod pažljivo je izrađen od najfinijeg drveta, birajući materijale koji su prirodni, izdržljivi i ekološki prihvatljivi. Naša misija je da našim kupcima obezbedimo češljeve koji ne samo da su funkcionalni, već i estetski privlačni.
          <br/><br/>
          Osim izrade vrhunskih proizvoda, posvećeni smo i očuvanju prirode. Koristimo održive metode proizvodnje i biramo materijale koji minimiziraju otpad i imaju najmanji ekološki otisak. Odaberite Janami Wood i uživajte u proizvodima koji kombinuju kvalitet, eleganciju i ekološku odgovornost.
        `
            });
        } else {
            setText({
                title: 'About Us',
                content: `
          Janami Wood is a workshop dedicated to creating high-quality wooden combs. Each of our products is carefully crafted from the finest wood, choosing materials that are natural, durable, and environmentally friendly. Our mission is to provide our customers with combs that are not only functional but also aesthetically appealing.
          <br/><br/>
          In addition to making top-notch products, we are also committed to preserving nature. We use sustainable production methods and choose materials that minimize waste and have the smallest ecological footprint. Choose Janami Wood and enjoy products that combine quality, elegance, and environmental responsibility.
        `
            });
        }
    };

    return (
        <div className="about">
            <img
                src="assets/image1.jpg" // Replace with your desired image URL for the header
                alt="About Header"
                className="about-header-image"
            />
            <div className="about-content">
                <div className="about-text">
                    <h2 className="about-header">{text.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.content}} />
                </div>
                <img
                    src="./logo512.png" // Replace with your desired image for the right side
                    alt="About Us"
                    className="about-right-image"
                />
            </div>
        </div>
    );
}

export default About;