import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashBar from '../main/dashbar';
import DashFooter from '../main/dashfooter';
import './style/products.scss';

function Products() {
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({
        name: '',
        descriptionEN: '',
        descriptionSR: '',
        image1: '',
        image2: '',
        image3: '',
        priceRSD: '',
        priceUSD: '',
        engraving: false
    });
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://api.janamiwood.com/products');
            setProducts(response.data);
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    };

    const fetchProduct = async (productId) => {
        try {
            const response = await axios.get(`https://api.janamiwood.com/products/${productId}`);
            const productData = response.data;
            // Making engraving boolean instead of a number
            productData.engraving = Boolean(productData.engraving);
            setProduct(productData);
            setEditId(productId);
        } catch (error) {
            console.error('Failed to fetch product:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setProduct(prevProduct => ({
            ...prevProduct,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleReset = () => {
        setProduct({
            name: '',
            descriptionEN: '',
            descriptionSR: '',
            image1: '',
            image2: '',
            image3: '',
            priceRSD: '',
            priceUSD: '',
            engraving: false
        });
        setEditId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editId) {
                await axios.put(`https://api.janamiwood.com/products/${editId}`, product);
            } else {
                await axios.post('https://api.janamiwood.com/products', product);
            }
            fetchProducts(); // Refresh the list after adding or updating a product
            handleReset();
        } catch (error) {
            console.error('Failed to save product:', error);
        }
    };

    const handleDelete = async (productId) => {
        try {
            await axios.delete(`https://api.janamiwood.com/products/${productId}`);
            fetchProducts(); // Refresh the list after deleting a product
        } catch (error) {
            console.error('Failed to delete product:', error);
        }
    };

    const handleEdit = (productId) => {
        fetchProduct(productId);
    };

    return (
        <>
            <DashBar />
            <div className="products-container">
                <div className="products-content">
                    <div className="products-box">
                        <div className="products-form-container">
                            <h1 className="products-section-title">{editId ? "Edit Product" : "Create Product"}</h1>
                            <form onSubmit={handleSubmit} className="products-form">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={product.name}
                                    onChange={handleInputChange}
                                    required
                                />

                                <label htmlFor="descriptionEN">Description (EN)</label>
                                <textarea
                                    id="descriptionEN"
                                    name="descriptionEN"
                                    value={product.descriptionEN}
                                    onChange={handleInputChange}
                                    required
                                ></textarea>

                                <label htmlFor="descriptionSR">Description (SR)</label>
                                <textarea
                                    id="descriptionSR"
                                    name="descriptionSR"
                                    value={product.descriptionSR}
                                    onChange={handleInputChange}
                                    required
                                ></textarea>

                                <label htmlFor="image1">Image 1 URL</label>
                                <input
                                    type="text"
                                    id="image1"
                                    name="image1"
                                    value={product.image1}
                                    onChange={handleInputChange}
                                    required
                                />

                                <label htmlFor="image2">Image 2 URL</label>
                                <input
                                    type="text"
                                    id="image2"
                                    name="image2"
                                    value={product.image2}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="image3">Image 3 URL</label>
                                <input
                                    type="text"
                                    id="image3"
                                    name="image3"
                                    value={product.image3}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="priceRSD">Price (RSD)</label>
                                <input
                                    type="number"
                                    id="priceRSD"
                                    name="priceRSD"
                                    value={product.priceRSD}
                                    onChange={handleInputChange}
                                    required
                                />

                                <label htmlFor="priceUSD">Price (USD)</label>
                                <input
                                    type="number"
                                    id="priceUSD"
                                    name="priceUSD"
                                    value={product.priceUSD}
                                    onChange={handleInputChange}
                                    required
                                />

                                <label htmlFor="engraving">Engraving</label>
                                <input
                                    type="checkbox"
                                    id="engraving"
                                    name="engraving"
                                    checked={product.engraving}
                                    onChange={handleInputChange}
                                />

                                <div className="form-actions">
                                    <button type="submit">{editId ? "Save Product" : "Add Product"}</button>
                                    {editId && <button type="button" onClick={handleReset}>Cancel</button>}
                                </div>
                            </form>
                        </div>

                        <div className="products-list-container">
                            <h2 className="products-section-title">Products</h2>
                            <div className="products-list">
                                {products.map((prod) => (
                                    <div key={prod.id} className="products-item">
                                        <div className="products-details">
                                            <div className="products-name">{prod.name}</div>
                                            <div className="products-description">{prod.descriptionEN}</div>
                                            <div className="products-price">{prod.priceRSD} RSD</div>
                                            <div className="products-price">{prod.priceUSD} USD</div>
                                            <div className="products-engraving">{prod.engraving ? 'Engraving Available' : 'No Engraving'}</div>
                                        </div>
                                        <div className="products-image-container">
                                            {prod.image1 && <img src={prod.image1} alt={prod.name} className="products-image" />}
                                            {prod.image2 && <img src={prod.image2} alt={prod.name} className="products-image" />}
                                            {prod.image3 && <img src={prod.image3} alt={prod.name} className="products-image" />}
                                        </div>
                                        <div className="products-actions">
                                            <button onClick={() => handleEdit(prod.id)}>Edit</button>
                                            <button onClick={() => handleDelete(prod.id)}>Delete</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DashFooter />
        </>
    );
}

export default Products;